import {
  APP_MANAGED_ROUTES,
  FEATURE_FLAGS,
  RegionSelectionClaims,
  ClaimsDetailDisclaimer,
} from '@highmark-web/highmark-common';
import { LeagueCombinedConfig } from '@leagueplatform/core';
import { firebaseRemoteConfig } from 'common/firebase';
import { getBoolean } from 'firebase/remote-config';

export function getClaimsConfig(): LeagueCombinedConfig['claims'] {
  return {
    showTotalCostOverviewAndAdditionalResources: false,
    supportLink: APP_MANAGED_ROUTES.SUPPORT,
    enableDynamicFilters: getBoolean(
      firebaseRemoteConfig,
      FEATURE_FLAGS.CLAIMS_ENABLE_DYNAMIC_FILTERS,
    ),
    featureFlags: {
      shouldShowClaimDetails: getBoolean(
        firebaseRemoteConfig,
        FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_CLAIM_DETAILS,
      ),
      shouldShowDownloadClaims: getBoolean(
        firebaseRemoteConfig,
        FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_DOWNLOAD_CLAIMS,
      ),
      shouldShowFilterChips: getBoolean(
        firebaseRemoteConfig,
        FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_FILTER_CHIPS,
      ),
    },
    slots: {
      Banner: RegionSelectionClaims,
      ServiceDetailsBanner: ClaimsDetailDisclaimer,
    },
    showHealthInsuranceGlossary: getBoolean(
      firebaseRemoteConfig,
      FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_CLAIM_INSURANCE_GLOSSARY,
    ),
  };
}
