import React, { useMemo, useState } from 'react';
import { PrivateBasePage } from 'components/private-base-page.component';
import {
  APP_MANAGED_ROUTES,
  ERROR_TYPES,
  ErrorState,
  INELIGIBLE_CODES,
  LegalPage,
  LIVE_CHAT_DROP_ZONES,
  LiveChatLayoutContext,
  LoadingSpinner,
  MfaEnrollment,
  PageEligibilityContext,
  RegionSelectionContext,
  useAnalyticsIdentify,
  usePrivateRoutesContainer,
} from '@highmark-web/highmark-common';
import { RewardsEventController } from '@leagueplatform/rewards-events';
import {
  ACHIEVEMENTS_ENABLED,
  REWARDS_EVENTS_ENABLED,
} from '@leagueplatform/rewards-api';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { useConfigProperty } from '@leagueplatform/core';
import { Route, Switch } from 'react-router-dom';
import BoxoutIcon from 'assets/boxout.svg';
import { WalkthroughContainer } from './app-walkthrough/walkthrough-container.component';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    contentConfig,
    regionSelection,
    eligibilityInfo,
    enableLiveChat,
    showWalkthrough,
    onCompleteWalkthrough,
    showMfa,
    onCompleteMfa,
    isLoading,
    errorAppInitDetails,
    errorUserProfile,
  } = usePrivateRoutesContainer();

  const [liveChatLayout, setLiveChatLayout] = useState(
    LIVE_CHAT_DROP_ZONES.BOTTOM_RIGHT,
  );
  const liveChatProviderValue = useMemo(
    () => ({ liveChatLayout, setLiveChatLayout }),
    [liveChatLayout, setLiveChatLayout],
  );

  const highmarkUrl = import.meta.env.VITE_MYHIGHMARK_APP_LOGIN_URL as string;
  const allMyHealthUrl = import.meta.env.VITE_AMH_APP_LOGIN_URL as string;

  const { data: isRewardsLayerEnabled } = useFeatureFlagQuery(
    REWARDS_EVENTS_ENABLED,
  );

  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  // Send user data to analytics SDK
  const tenantId = useConfigProperty('core.tenantId');
  useAnalyticsIdentify(tenantId!);

  if (isLoading) return <LoadingSpinner />;

  if (errorAppInitDetails !== null) {
    return (
      <ErrorState
        error={errorAppInitDetails}
        isFullPage
        errorType={ERROR_TYPES.API_ERROR}
      />
    );
  }

  // Show not eligible screen if user isn't eligible to use the app
  if (!eligibilityInfo.eligible) {
    // Show specific not eligible screen if user hasn't had coverage for over 365 days
    switch (eligibilityInfo.reasonCode) {
      case INELIGIBLE_CODES.OVER_365_DAYS:
        return <ErrorState isFullPage errorType={ERROR_TYPES.OVER_365_DAYS} />;
      case 19:
        return (
          <ErrorState
            isFullPage
            imageSrc={BoxoutIcon}
            buttonLink={highmarkUrl}
            errorType={ERROR_TYPES.NOT_ELIGIBLE_MYHIGHMARK}
          />
        );
      case 17:
        return (
          <ErrorState
            isFullPage
            imageSrc={BoxoutIcon}
            buttonLink={allMyHealthUrl}
            errorType={ERROR_TYPES.NOT_ELIGIBLE_ALLMYHEALTH}
          />
        );
      default:
        return <ErrorState isFullPage errorType={ERROR_TYPES.NOT_ELIGIBLE} />;
    }
  }

  // Show error screen if user is eligible, but userProfile API fails
  if (errorUserProfile !== null)
    return (
      <ErrorState
        error={errorUserProfile}
        isFullPage
        errorType={ERROR_TYPES.API_ERROR}
      />
    );

  // Show walkthrough slides if needed
  if (showWalkthrough) {
    return (
      <Switch>
        <Route path={APP_MANAGED_ROUTES.LEGAL} component={LegalPage} />
        <Route>
          <WalkthroughContainer onCompleteWalkthrough={onCompleteWalkthrough} />
        </Route>
      </Switch>
    );
  }

  if (showMfa) {
    return <MfaEnrollment onEnrollSuccess={onCompleteMfa} />;
  }

  return (
    <RegionSelectionContext.Provider value={regionSelection}>
      <LiveChatLayoutContext.Provider value={liveChatProviderValue}>
        <PrivateBasePage enableLiveChat={enableLiveChat}>
          {isRewardsLayerEnabled && isAchievementsEnabled && (
            <RewardsEventController />
          )}
          <PageEligibilityContext.Provider value={contentConfig}>
            {children}
          </PageEligibilityContext.Provider>
        </PrivateBasePage>
      </LiveChatLayoutContext.Provider>
    </RegionSelectionContext.Provider>
  );
};
